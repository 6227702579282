import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/logo.png";
import NavigationLink from "./navigation-link";
import { useEffect, useState } from "react";
import MobileMenu from "./mobile-menu";
import NavAuth from "./auth";
import { BsHandbag } from "react-icons/bs";
import useCart from "../utils/context/cart-context";

function TopNav() {
  const [openNav, setOpenNav] = useState(false);
  const { cart } = useCart();
  const location = useLocation();
  const isHome = location.pathname === "/";
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  return (
    <nav
      className={`w-full mx-auto py-3  lg:py-4 overflow-x-hidden z-50 ${
        isHome
          ? "absolute top-0 left-0 bg-white"
          : "relative bg-white border-b border-gray-100"
      }`}
    >
      <div className="context">
        <div className="relative flex items-center justify-between">
          <div className="cmaths-logo">
            <Link to="/">
              <img src={Logo} alt="Logo" />
            </Link>
          </div>
          <div className="cmaths-nav-links  hidden lg:block">
            <ul className="flex flex-wrap flex-row  gap-x-4">
              <li>
                <NavigationLink to="/">Home</NavigationLink>
              </li>
              <li>
                <NavigationLink to="/tutors/65c7413374027789ccfff261">
                  Classes
                </NavigationLink>
                {/* <NavigationLink to="/courses">Subjects</NavigationLink> */}
              </li>
              <li>
                <NavigationLink to="/my-courses">My Courses</NavigationLink>
              </li>
              <li>
                <a
                  href="https://support.combinedmaths.lk"
                  className="text-cmathsDark hover:text-cmathsPrimary hover:bg-primary-gradient hover:text-transparent hover:bg-clip-text transition-all duration-300 ease-in-out"
                >
                  Support
                </a>
              </li>
            </ul>
          </div>
          <div className="relative flex items-center">
            <div className="relative mr-2 lg:mr-10">
              <Link to="/cart">
                <BsHandbag className="text-cmathsDark" size={24} />
                <span className="bg-primary-gradient text-white rounded-full absolute top-[-6px] -right-[12px] lg:-right-[20px] flex items-center justify-center w-4 h-4 text-[10px] md:w-5 md:h-5 md:text-xs">
                  {Object.keys(cart).length}
                </span>
              </Link>
            </div>
            <NavAuth
              openNav={openNav}
              setOpenNav={setOpenNav}
              className="!hidden lg:!flex"
            />
            {/* Mobile Menu Button */}
            <div className="relative ml-4 block lg:hidden">
              <button
                className="w-[25px] h-[25px] flex items-center justify-center"
                onClick={() => setOpenNav(!openNav)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="#ffffff"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="3" x2="21" y1="6" y2="6" />
                  <line x1="3" x2="21" y1="12" y2="12" />
                  <line x1="3" x2="21" y1="18" y2="18" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <MobileMenu openNav={openNav} setOpenNav={setOpenNav} />
      </div>
    </nav>
  );
}

export default TopNav;

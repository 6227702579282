import { Link } from "react-router-dom";
import useAuth from "../utils/context/auth-context";

const SignInReminderPopup = () => {
  const { reminder, setReminder } = useAuth();

  return (
    <>
      <div className="absolute top-0 left-0 bg-black/80 w-full min-h-screen flex justify-center items-center overflow-hidden z-50">
        <div className="auth-model-inner">
          <div className="absolute right-[10px] top-[10px]">
            <div className="relative">
              <button
                onClick={() => setReminder(!reminder)}
                className="relative flex justify-center items-center cursor-pointer w-6 h-6 bg-light rounded-lg  transition-all duration-300 ease-in-out z-50"
              >
                <svg
                  width="11"
                  height="11"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.40994 8L15.7099 1.71C15.8982 1.5217 16.004 1.2663 16.004 1C16.004 0.733701 15.8982 0.478306 15.7099 0.290002C15.5216 0.101699 15.2662 -0.00408936 14.9999 -0.00408936C14.7336 -0.00408936 14.4782 0.101699 14.2899 0.290002L7.99994 6.59L1.70994 0.290002C1.52164 0.101699 1.26624 -0.00408912 0.999939 -0.00408912C0.733637 -0.00408912 0.478243 0.101699 0.289939 0.290002C0.101635 0.478306 -0.00415253 0.733701 -0.00415254 1C-0.00415254 1.2663 0.101635 1.5217 0.289939 1.71L6.58994 8L0.289939 14.29C0.196211 14.383 0.121816 14.4936 0.0710478 14.6154C0.0202791 14.7373 -0.00585938 14.868 -0.00585938 15C-0.00585938 15.132 0.0202791 15.2627 0.0710478 15.3846C0.121816 15.5064 0.196211 15.617 0.289939 15.71C0.382902 15.8037 0.493503 15.8781 0.615362 15.9289C0.737221 15.9797 0.867927 16.0058 0.999939 16.0058C1.13195 16.0058 1.26266 15.9797 1.38452 15.9289C1.50638 15.8781 1.61698 15.8037 1.70994 15.71L7.99994 9.41L14.2899 15.71C14.3829 15.8037 14.4935 15.8781 14.6154 15.9289C14.7372 15.9797 14.8679 16.0058 14.9999 16.0058C15.132 16.0058 15.2627 15.9797 15.3845 15.9289C15.5064 15.8781 15.617 15.8037 15.7099 15.71C15.8037 15.617 15.8781 15.5064 15.9288 15.3846C15.9796 15.2627 16.0057 15.132 16.0057 15C16.0057 14.868 15.9796 14.7373 15.9288 14.6154C15.8781 14.4936 15.8037 14.383 15.7099 14.29L9.40994 8Z"
                    fill="#3B5079"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="relative py-2 text-center mb-2">
            <h4 className="text-xl sm:text-2xl text-dark sm:mb-1">
              Greetings 🎉
            </h4>
            <p className="text-para text-sm">
              For access to your Institute's LMS features and personalized
              recommendations, kindly sign in.
            </p>
          </div>
          <div className="relative flex flex-row justify-center items-center gap-x-4 ">
            <Link
              onClick={() => setReminder(!reminder)}
              to="/auth/register"
              className="btn btn-md btn-white-blue"

            >
              Register
            </Link>
            <Link
              onClick={() => setReminder(!reminder)}
              to="/auth/login"
              className="btn btn-gradient-md btn-primary-gradient"
            >
              Sign In
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignInReminderPopup;
